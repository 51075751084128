import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import AccessTable from './AccessTable';
import { StaffType, AccountMasInvitedStaff } from '../../../common/constants';
import ApiService  from '../../../services/apiService';
import Colours from '../../../styles/colours';
import { BusinessStaffSchema } from '../../../common/validationSchemas';
import StyledRadio  from '../../../components/StyledRadio';
import {  IsEmpty, IsAdminOnly } from '../../../common/checks';
import { containerHeaderTextStyle } from '../../../styles/common';
import Tooltip from '../../../components/Common/ToolTip';

function Access({ pageSelected }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [planModals, setPlanModals] = useState(false);
  const [currentStaffList, setCurrentStaffList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [businessStaff, setBusinessStaff] = useState({
    inviteEmail: '',
    type:        StaffType.Contributor,
  });
  const [errorObj, setErrorObj] = useState({
    inviteEmail: {
      message: '',
      error:   false,
    },
  });

  const resetBusinessStaff = () => {
    setBusinessStaff({ inviteEmail: '', type: StaffType.Contributor });
    setErrorObj({ inviteEmail: { message: '', error: false } });
  };

  const getBusinessStaff = async () => {
    setIsLoading(true);
    ApiService.getBusinessStaff({ pageId: pageSelected.id })
      .then((data) => {
        setCurrentStaffList(data);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangePlanModal = () => setPlanModals(!planModals);

  const createBusinessStaff = async () => {
    try {
      setIsRequesting(true);
      const valid = await BusinessStaffSchema.isValid(businessStaff);
      if (valid) {
        await ApiService.createBusinessStaff(pageSelected.id, businessStaff);
        await getBusinessStaff();
        toast.success('Invitation has been emailed to staff');
        resetBusinessStaff();
        setIsOpenModal(false);
      } else {
        toast.warning('Please enter valid email');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsRequesting(false);
    }
  };

  useEffect(() => {
    getBusinessStaff();
  }, []);

  const handleBlurChange = (name, value) => {
    setBusinessStaff((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    /**
    * INSTANCE VALIDATION WITH YUP SCHEMA
    */
    BusinessStaffSchema.validate({
      ...businessStaff,
      [name]: value,
    })
      .then(() => {
        setErrorObj((prevState) => ({
          ...prevState,
          [name]: {
            error:   false,
            message: '',
          },
        }));
      })
      .catch((error) => {
        const errorName = error.errors[0].split('-')[0];
        const errorMsg = error.errors[0].split('-')[1];
        if (name === errorName) {
          const catchedErrorObj = {
            error:   true,
            message: errorMsg,
          };
          setErrorObj((prevState) => ({
            ...prevState,
            [name]: catchedErrorObj,
          }));
        } else {
          setErrorObj((prevState) => ({
            ...prevState,
            [name]: {
              error:   false,
              message: '',
            },
          }));
        }
      });
  };

  const handleInputChange = (name, value) => {
    setBusinessStaff((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    /**
     * INSTANCE VALIDATION WITH YUP SCHEMA
    */
    BusinessStaffSchema.validate({
      ...businessStaff,
      [name]: value,
    })
      .then(() => {
        setErrorObj((prevState) => ({
          ...prevState,
          [name]: {
            error:   false,
            message: '',
          },
        }));
      })
      .catch((error) => {
        const errorName = error.errors[0].split('-')[0];
        const errorMsg = error.errors[0].split('-')[1];
        if (name === errorName) {
          const catchedErrorObj = {
            error:   true,
            message: errorMsg,
          };
          setErrorObj((prevState) => ({
            ...prevState,
            [name]: catchedErrorObj,
          }));
        } else {
          setErrorObj((prevState) => ({
            ...prevState,
            [name]: {
              error:   false,
              message: '',
            },
          }));
        }
      });
  };

  const openInviteStaffModal = () => {
    const maxNoStaff = AccountMasInvitedStaff[pageSelected.plan];

    if (currentStaffList.length < maxNoStaff) {
      setIsOpenModal(true);
    } else {
      onChangePlanModal();
    }
  };

  return (
    <div className="container-fluid">
      <Dialog
        open={isOpenModal}
        onClose={() => {
          resetBusinessStaff();
          setIsOpenModal(false);
        }}
      >
        <DialogTitle className="px-5 pt-5" id="alert-dialog-title">
          Invite Staff
        </DialogTitle>
        <DialogContent className="px-5">
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12">
                <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Black }}>Type</Typography>
              </div>
              <div className="col-md-12">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="stafftype"
                    row
                    name="Staff Type"
                    value={businessStaff.type}
                    onChange={(e) => { handleInputChange('type', e.target.value); }}
                  >
                    <FormControlLabel color="primary" value={StaffType.Admin} control={<StyledRadio />} label={StaffType.Admin} />
                    <FormControlLabel value={StaffType.Contributor} control={<StyledRadio />} label={StaffType.Contributor} />
                    <FormControlLabel value={StaffType.Observer} control={<StyledRadio />} label={StaffType.Observer} />
                    <FormControlLabel value={StaffType.Finance} control={<StyledRadio />} label={StaffType.Finance} />
                  </RadioGroup>
                </FormControl>
                {' '}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Black }}>
                  Email
                  {' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </div>
              <div className="col-12">
                <TextField
                  className="mt-2 block"
                  name="inviteEmail"
                  type="email"
                  value={businessStaff.inviteEmail}
                  onChange={(e) => handleInputChange('inviteEmail', e.target.value)}
                  onBlur={(e) => handleBlurChange('inviteEmail', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.inviteEmail.message}
                  error={errorObj.inviteEmail.error}
                />
              </div>
            </div>
          </div>

        </DialogContent>
        <DialogActions className="my-3 pb-3 px-5">
          <Button
            variant="outlined"
            color="default"
            className="px-4 mr-3"
            disabled={isRequesting}
            onClick={() => {
              resetBusinessStaff();
              setIsOpenModal(false);
            }}
          >
            Cancel
          </Button>
          &nbsp;
          <Button
            disableElevation
            className="px-4"
            variant="contained"
            color="primary"
            onClick={() => {
              createBusinessStaff();
            }}
            disabled={IsEmpty(businessStaff.inviteEmail) || errorObj.inviteEmail.error || isRequesting}
          >
            Invite
            {
              isRequesting && <CircularProgress className="ml-2" size="1rem" />
            }
          </Button>
        </DialogActions>
      </Dialog>
      {/* Plans Dialog */}
      <Dialog
        open={planModals}
        onClose={() => onChangePlanModal()}
        fullWidth
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Maximum number of staff invite is reached</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Your current account plan is :
            {' '}
            <span style={{ fontWeight: 'bold', color: Colours.Black }}>
              {pageSelected.plan}
              {' '}
              Tier
            </span>
            <br />
            Staff invite quota:
            {' '}
            <span style={{ fontWeight: 'bold', color: Colours.Black }}>{AccountMasInvitedStaff[pageSelected.plan]}</span>
            {' '}
            <br />
            <br />
            To find out more or upgrade your subscription plan, click
            {' '}
            <a href={`/support?upgradePlan=true&pageName=${pageSelected.name}&pageId=${pageSelected.id}`}>here</a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-2">
          <Button className="px-3 mx-3" onClick={() => onChangePlanModal()} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <div className="mt-4 ml-2 mb-2 d-flex flex-row justify-content-start align-items-center">
        <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Access</Typography>
        <Tooltip title={IsAdminOnly(pageSelected.staffType) ? 'Invite staff to access this page' : 'Permission Denied'}>
          <Button
            size="medium"
            className="ml-3 px-3"
            variant="contained"
            type="submit"
            color="primary"
            onClick={openInviteStaffModal}
            disabled={!IsAdminOnly(pageSelected.staffType)}
          >
            + Invite Staff
          </Button>
        </Tooltip>
      </div>

      <AccessTable
        currentStaffList={currentStaffList}
        pageSelected={pageSelected}
        setCurrentStaffList={setCurrentStaffList}
        disabled={!IsAdminOnly(pageSelected.staffType)}
        isLoading={isLoading}
      />
    </div>
  );
}

Access.propTypes = {
  pageSelected: PropTypes.shape({
    id:        PropTypes.number,
    name:      PropTypes.string,
    staffType: PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Access);
