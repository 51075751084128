// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconButton, Button, Typography, Card, TextField, RadioGroup, Radio, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

// Internal Dependencies
import Photo from './Photo';
import MaterialDatePicker from '../../../../../components/Common/form/MaterialDatePicker';
import Icons from '../../../../../components/Common/Icons';
import Colours from '../../../../../styles/colours';
import postStyle from '../../../../../styles/post';
import ApiService from '../../../../../services/apiService';
import OverLoading from '../../../../../components/Loading';
import { containerHeaderTextStyle } from '../../../../../styles/common';
import { Status } from '../../../../../common/constants';

const useStyles = makeStyles(({
  alterDateContainer: {
    width: '50%',

    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
}));

const Gift = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const { pageId, giftId } = props.match.params;

  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [extendedExpiryDate, setExtendedExpiryDate] = useState(null);
  const [issuableQuantity, setIssuableQuantity] = useState('');
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [status, setStatus] = useState(Status.Draft);
  const [isHqAuthToIssue, setIsHqAuthToIssue] = useState(false);
  const [pauseIssuance, setPauseIssuance] = useState(false);
  const [type, setType] = useState(null);
  const [images, setImages] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getGiftDataHandler = async () => {
    try {
      setLoading(true);
      const gift = await ApiService.getGift({ giftId, pageId });

      setDescription(gift.label);
      setStartDate(gift.startDate);
      setEndDate(gift.endDate);
      setExtendedExpiryDate(gift.extendedEndDate);
      setIssuableQuantity(gift.issuableQty || '');
      setTermsAndCondition(gift.terms || '');
      setStatus(gift.status);
      setIsHqAuthToIssue(gift.isHqAuthToIssue || false);
      setPauseIssuance(gift.pauseIssuance || false);
      setType(gift.type);
      setImages(gift.images);
      setData(gift);
    } catch (err) {
      console.log('Error in getGiftDataHandler: ', err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const saveGiftDataHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        label:           description,
        startDate,
        endDate,
        extendedEndDate: extendedExpiryDate,
        issuableQty:     Number(issuableQuantity) <= 0 ? 0 : Number(issuableQuantity),
        terms:           termsAndCondition,
        type,
        status,
        isHqAuthToIssue,
        pauseIssuance,
        images,
      };

      if ((!_.isEmpty(startDate) && !_.isEmpty(endDate)) && moment(endDate).isSameOrBefore(moment(startDate))) {
        return toast.error('End date should not exceed the start date!');
      } else if ((!_.isEmpty(endDate) && !_.isEmpty(extendedExpiryDate)) && moment(extendedExpiryDate).isSameOrBefore(moment(endDate))) {
        return toast.error('Extended Expiry date should not exceed the end date!');
      } else if (payload.issuableQty <= 0) {
        return toast.error('Issuable quantity should be greater than 0');
      } else if (payload.status === Status.Activated && _.isEmpty(images)) {
        return toast.error('To activate the gift, please upload at least one gift image');
      }

      await ApiService.updateGift({ giftId, pageId }, payload);
      toast.success('Gift updated successfully');

      await getGiftDataHandler(); // get updated data
    } catch (err) {
      console.log('Error in saveGiftDataHandler: ', err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return moment(newDate).format('YYYY-MM-DD');
  };

  useEffect(() => {
    getGiftDataHandler();
  }, []);

  return (
    <div className="container-fluid">
      { loading && <OverLoading /> }
      <div className="m-0 mt-md-4 ml-md-2 mb-md-2">
        <div className="d-inline-flex align-items-center mb-3">
          <IconButton style={{ width: 50, height: 50 }} onClick={() => history.goBack()}>
            <Icons.ArrowBack colour={Colours.Black} />
          </IconButton>
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Edit Voucher</Typography>
        </div>

        <Card className="px-3 px-md-4 pt-2 pb-4">
          <Typography className="mt-2" variant="body2" style={{ color: '#8B8989' }}>
            Voucher ID:
            {' '}
            {giftId}
          </Typography>

          <div className="mt-4 mb-4">
            <Typography variant="subtitle1" color="inherit" className="mt-2 mb-1" style={postStyle.textHeader}>Description</Typography>
            <TextField
              value={description || ''}
              onChange={(e) => setDescription(e.currentTarget.value)}
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  fontSize: '14px',
                  width:    '18rem',
                },
              }}
            />

            { (!_.isEmpty(data) && data.status === Status.Activated) ? (
              <div className={`alert alert-warning mt-3 ${classes.alterDateContainer}`} role="alert">
                <Typography variant="body1" style={{ fontSize: '0.9rem' }}>
                  Dates cannot be changed once live.
                </Typography>
                <br />
                <hr />
                { data.startDate && data.endDate ? (
                  <div>
                    <h6><strong>{`Start Date: ${moment(new Date(data.startDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong></h6>
                    <h6><strong>{`End Date: ${moment(new Date(data.endDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong></h6>
                    { data.extendedEndDate && (
                      <h6>
                        <strong>{`Extended Expiry Date: ${moment(new Date(data.extendedEndDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong>
                      </h6>
                    ) }
                  </div>
                ) : <Typography variant="body2" style={{ fontWeight: 'bold' }}>Dates not specified</Typography>}
              </div>
            ) : (
              <>
                <Typography variant="subtitle1" color="inherit" className="mt-3 mb-1" style={postStyle.textHeader}>Start Date</Typography>
                <MaterialDatePicker
                  minDate={false}
                  variant="outlined"
                  value={startDate}
                  onChange={(e) => setStartDate(formatDate(e.toString('yyyy-MM-dd')))}
                  inputStyle={{ padding: '10.5px 14px', fontSize: '14px' }}
                  className="mt-0 mb-0"
                />

                <Typography variant="subtitle1" color="inherit" className="mt-3 mb-1" style={postStyle.textHeader}>End Date</Typography>
                <MaterialDatePicker
                  minDate={!startDate ? false : new Date(startDate)}
                  variant="outlined"
                  value={endDate}
                  onChange={(e) => setEndDate(formatDate(e.toString('yyyy-MM-dd')))}
                  inputStyle={{ padding: '10.5px 14px', fontSize: '14px' }}
                  className="mt-0 mb-0"
                />

                <Typography variant="subtitle1" color="inherit" className="mt-3 mb-1" style={postStyle.textHeader}>Extended Expiry Date</Typography>
                <MaterialDatePicker
                  minDate={!endDate ? false : new Date(endDate)}
                  variant="outlined"
                  value={extendedExpiryDate}
                  onChange={(e) => setExtendedExpiryDate(formatDate(e.toString('yyyy-MM-dd')))}
                  inputStyle={{ padding: '10.5px 14px', fontSize: '14px' }}
                  style={{ backgroundColor: '#EEEEEE', borderRadius: '5px' }}
                  className="mt-0 mb-0"
                />
              </>
            )}

            <Typography variant="subtitle1" color="inherit" className="mt-3 mb-1" style={postStyle.textHeader}>Issuable Quantity</Typography>
            <TextField
              value={issuableQuantity || ''}
              onChange={(e) => setIssuableQuantity(e.currentTarget.value)}
              variant="outlined"
              size="small"
              type="number"
              inputProps={{
                min:   1,
                style: {
                  fontSize: '14px',
                  width:    '12.75rem',
                },
              }}
            />

            <Typography variant="subtitle1" color="inherit" className="mt-3 mb-1" style={postStyle.textHeader}>Photo</Typography>
            <Photo
              giftId={giftId}
              gift={data}
              onUpdateImages={setImages}
            />

            <Typography variant="subtitle1" color="inherit" className="mt-5 mb-1" style={postStyle.textHeader}>T&Cs</Typography>
            <TextField
              multiline
              minRows={4}
              value={termsAndCondition || ''}
              onChange={(e) => setTermsAndCondition(e.currentTarget.value)}
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  fontSize: '14px',
                  width:    isMobile ? '18rem' : '26rem',
                },
              }}
            />

            <Typography variant="subtitle1" color="inherit" className="mt-4 mb-0" style={postStyle.textHeader}>HQ Issuance</Typography>
            <FormControlLabel
              className="ml-2"
              control={<Checkbox checked={isHqAuthToIssue} onChange={(e) => setIsHqAuthToIssue(e.target.checked)} name="gilad" />}
              label="HQ business staff (admin and editor) can issue voucher"
            />

            <Typography variant="subtitle1" color="inherit" className="mt-3 mb-0" style={postStyle.textHeader}>Status</Typography>
            <div className="ml-2 mb-1">
              <RadioGroup row={!isMobile} name="giftType" value={status} onChange={(e) => setStatus(e.target.value)}>
                <FormControlLabel
                  className="mb-0"
                  value={Status.Draft}
                  control={<Radio color="primary" />}
                  label={Status.Draft}
                />
                <FormControlLabel
                  className="mb-0"
                  value={Status.Activated}
                  control={<Radio color="primary" />}
                  label={`${Status.Activated} ^`}
                />
                <FormControlLabel
                  className="mb-0"
                  value={Status.Deactivated}
                  control={<Radio color="primary" />}
                  label={Status.Deactivated}
                />
              </RadioGroup>

              <span style={{ fontSize: '14px' }}>^ Once activated, you will not be able to change the start and expiry dates</span>
            </div>

            <Typography variant="subtitle1" color="inherit" className="mt-4 mb-0" style={postStyle.textHeader}>Pause Issuance</Typography>
            <Typography
              variant="body1"
              className="m-0"
              style={{ fontSize: '14px' }}
            >
              Check this box to stop new vouchers from being issued
            </Typography>
            <FormControlLabel
              className="ml-2"
              control={<Checkbox checked={pauseIssuance} onChange={(e) => setPauseIssuance(e.target.checked)} name="gilad" />}
              label="Pause Issuance"
            />
          </div>

          <Button className="ml-3" variant="contained" color="secondary" style={{ width: '7rem' }} onClick={saveGiftDataHandler}>Save</Button>
        </Card>
      </div>
    </div>
  );
};

Gift.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageId: PropTypes.string,
      giftId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Gift;
