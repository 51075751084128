// React Dependencies
import React, { useState, useEffect, useReducer } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Paper, TextField, RadioGroup, Radio, FormControlLabel, Button, makeStyles, Dialog, Checkbox } from '@material-ui/core';

// Internal Dependencies
import MText from '../../../components/Common/MText';
import ApiService from '../../../services/apiService';
import OverLoading from '../../../components/Loading';
import Colours from '../../../styles/colours';
import Photo from './Photo';
import { IsEmpty } from '../../../common/checks';
import { getImageSize } from '../../../common/util';
import { Status } from '../../../common/constants';
import { labelTextSize, titleTextSize } from './LoyaltyCardStyle';

const useStyles = makeStyles({
  saveButton: {
    width: '100%',

    '@media (min-width: 992px)': {
      width: '50%',
    },
  },
});

const confirmationImpactTexts = {
  [Status.Live]: [
    'Loyalty Card will be publicly available',
    'Number of stamps in the card cannot be modified',
    'Card’s validity period cannot be updated',
    'Status cannot be updated back to ’Draft’',
  ],
  [Status.Deactivated]: [
    'The loyalty card will no longer be publicly available',
    'All information of the loyalty card cannot be modified',
    'Status cannot be updated back to Live',
  ],
};

const defaultTextInputs = {
  loyaltyCardName:    '',
  termsAndConditions: '',
  validationDays:     null,
  status:             Status.Draft,
  stampTextColour:    null,
};

const defaultConfirmDialogState = {
  open:      false,
  confirmed: false,
  context:   null, // either updating to Live or Deactivated
};

const LoyaltyCardInfo = ({ match }) => {
  const { pageId, loyaltyCardId } = match.params;
  const classes = useStyles();

  const [inputs, setInputs] = useReducer((state, action) => ({ ...state, ...action }), defaultTextInputs);
  const [confirmDialog, setConfirmDialog] = useReducer((state, action) => ({ ...state, ...action }), defaultConfirmDialogState);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialStatus, setInitialStatus] = useState(null);

  const isDeactivated = initialStatus === Status.Deactivated;
  const isDraft = initialStatus === Status.Draft;
  const isLive = initialStatus === Status.Live;

  const getLoyaltyCard = async () => {
    try {
      setLoading(true);
      const { name, terms, validityInDays, status, stampTextColour, mainImage } = await ApiService.getLoyaltyCard(pageId, loyaltyCardId);
      setInputs({ name, terms, validityInDays, status, stampTextColour });
      setImage(mainImage);
      setInitialStatus(status);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputs = (e) => {
    setInputs({ [e.target.name]: e.target.type === 'number' ? Number(e.target.value) : e.target.value });
  };

  const closeDialog = () => {
    setConfirmDialog({ open: false, confirmed: false });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const { name, terms, validityInDays, status, stampTextColour } = await ApiService.updateLoyaltyCard(pageId, loyaltyCardId, inputs);
      setInputs({ name, terms, validityInDays, status, stampTextColour });
      setInitialStatus(status);
      closeDialog();
      toast.success('Loyalty card updated successfully');
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const validateImage = async (imageObj) => {
    const imageSize = await getImageSize(imageObj.data);
    if (imageSize.width <= 900) {
      toast.error('The image width should not be less than 900 pixels');
      return false;
    }
    return true;
  };

  const handleImage = async (imageObj) => {
    const formData = new FormData();
    formData.append('image', imageObj.file);
    try {
      setLoading(true);
      const isValidated = await validateImage(imageObj);
      if (isValidated) {
        const mediaUrl = await ApiService.uploadLoyaltyCardMedia(pageId, loyaltyCardId, formData);
        setImage(mediaUrl);
        toast.success('Loyalty card image has been updated');
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const checkStatusTransitionDisabled = (status) => {
    if (isLive) {
      return status === Status.Draft;
    } else if (isDraft) {
      return status === Status.Deactivated;
    } else if (isDeactivated) {
      return [Status.Live, Status.Draft].includes(status);
    }
    return false;
  };

  useEffect(() => {
    getLoyaltyCard();
  }, []);

  return (
    <>
      {loading && <OverLoading />}
      <Paper className="py-3 mb-2 pl-3 pl-lg-5 mx-2 mb-3">
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 col-xl-6 pl-0">
              <MText
                size={labelTextSize}
                text={`Loyalty Card ID: ${match.params.loyaltyCardId}`}
                colour={Colours.Gray}
                className="mb-2"
              />
              <MText
                bold
                text="Card Info"
                size={titleTextSize}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-xl-6 pl-0">
              <MText size={labelTextSize} text="Loyalty Card Image" className="mb-2" />
              <div className="w-100">
                <Photo
                  title="+ Click to upload image"
                  image={image}
                  disabled={isDeactivated}
                  handleImage={handleImage}
                />
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-xl-6 pl-0">
              <MText size={labelTextSize} text="Loyalty Card Name" className="mb-1" />
              <div>
                <TextField
                  name="name"
                  disabled={isDeactivated}
                  value={inputs.name}
                  variant="outlined"
                  onChange={handleInputs}
                  InputProps={{ style: { fontSize: labelTextSize } }}
                  size="small"
                  className="w-100"
                />
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-xl-6 pl-0">
              <MText size={labelTextSize} text="Terms & Conditions" className="mb-1" />
              <div>
                <TextField
                  multiline
                  disabled={isDeactivated}
                  minRows={5}
                  name="terms"
                  value={inputs.terms}
                  variant="outlined"
                  onChange={handleInputs}
                  InputProps={{ style: { fontSize: labelTextSize } }}
                  className="w-100"
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: '2rem' }}>
            <div className="col-12 col-xl-6 pl-0">
              <MText size={labelTextSize} text="Card's Validity" />
              <MText
                size={11}
                text="Indicate how many day(s) the card will be valid when it is issued to the user"
                className="mb-2"
                colour={Colours.Gray1}
              />
              <div className="d-flex align-items-center">
                <TextField
                  variant="outlined"
                  size="small"
                  name="validityInDays"
                  value={inputs.validityInDays}
                  type="number"
                  placeholder="E.g. 365"
                  onChange={handleInputs}
                  className="mr-2"
                  disabled={[Status.Live, Status.Deactivated].includes(initialStatus)}
                  InputProps={{
                    style: {
                      width:     100,
                      fontSize:  labelTextSize,
                      fontStyle: IsEmpty(inputs.validityInDays) && 'italic',
                    },
                  }}
                />
                <MText text="days" size={labelTextSize} />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-xl-5 pl-0">
              <MText size={labelTextSize} text="Status" />
              <RadioGroup row name="status" value={inputs.status} onChange={handleInputs} defaultValue={Status.Draft}>
                {[Status.Draft, Status.Live, Status.Deactivated].map((status) => {
                  const isDisabled = checkStatusTransitionDisabled(status);
                  return (
                    <div className="mr-3">
                      <FormControlLabel
                        value={status}
                        control={<Radio color="secondary" disabled={isDisabled} />}
                        label={<MText size={labelTextSize} text={status} colour={isDisabled ? Colours.Gray2 : Colours.Black} />}
                      />
                    </div>
                  );
                })}
              </RadioGroup>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-12 col-xl-6 pl-0 d-flex justify-content-center">
              <Button
                disabled={isDeactivated}
                variant="contained"
                color="secondary"
                className={classes.saveButton}
                onClick={() => {
                  if ((isDraft && inputs.status === Status.Live) || ((isLive && inputs.status === Status.Deactivated))) {
                    setConfirmDialog({ open: true, context: inputs.status });
                  } else {
                    handleSave();
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Paper>

      <Dialog open={confirmDialog.open} onClose={closeDialog} PaperProps={{ className: 'w-100 p-5' }}>
        <u><MText text="Confirmation" size={titleTextSize} className="mb-2" /></u>
        <MText size={titleTextSize} className="mb-3">
          Updating status to
          {' '}
          {confirmDialog.context === Status.Live && <b>{Status.Live}</b>}
          {confirmDialog.context === Status.Deactivated && <b><u>{Status.Deactivated.toUpperCase()}</u></b>}
          {' '}
          will have the following impact:
        </MText>
        <ul className="ml-n4 ml-lg-0">
          {!IsEmpty(confirmDialog.context) && confirmationImpactTexts[confirmDialog.context].map((text) => (
            <li><MText text={text} size={titleTextSize} className="mb-3" /></li>
          ))}
        </ul>
        <MText text="Proceed" size={titleTextSize} className="mb-1" />
        <div className="d-flex align-items-center mb-4">
          <Checkbox
            onChange={(e) => setConfirmDialog({ confirmed: e.target.checked })}
            name="confirm"
            color="secondary"
            style={{ marginLeft: '-0.75rem' }}
          />
          <MText
            bold
            size={titleTextSize}
            className="ml-2"
          >
            I understand the impact and would like to proceed to update the loyalty card’s status to
            {' '}
            {confirmDialog.context === Status.Live && Status.Live}
            {confirmDialog.context === Status.Deactivated && Status.Deactivated.toUpperCase()}
          </MText>
        </div>
        <div className="w-100 d-flex justify-content-end align-items-center">
          <Button
            variant="outlined"
            style={{ width: 100 }}
            className="mr-4"
            onClick={closeDialog}
          >
            <MText size={14} text="Cancel" />
          </Button>
          <Button
            disabled={!confirmDialog.confirmed}
            variant="contained"
            color="secondary"
            style={{ width: 100 }}
            onClick={handleSave}
          >
            <MText size={14} text={confirmDialog.context === Status.Deactivated ? 'Deactivate' : 'Update'} colour={null} />
          </Button>
        </div>
      </Dialog>
    </>
  );
};

LoyaltyCardInfo.propTypes = {
  match: PropTypes.shape(Object).isRequired,
};

export default LoyaltyCardInfo;
