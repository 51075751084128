// React Dependencies
import React from 'react';

// Extenral Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Typography, Backdrop, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Internal Dependencies
import layoutAction from '../../actions/layout';
import Colours from '../../styles/colours';
import { PageType, Url } from '../../common/constants';
import Icons        from '../Common/Icons';
import AppMenuSidebarMobile from './AppMenuSidebarMobile';
import { IsEmpty,  IsAdminOnly, IsAdminOrContributor, IsTrue } from '../../common/checks';
import ApiService from '../../services/apiService';

class PageSideBarMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGiftEnabled: false,
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  onLoyaltyProgramLinkClickedHandler = () => {
    this.props.dispatchLoyaltyProgramSideBarShow();
  }

  async fetchPage() {
    const page = await ApiService.getPageById(this.props.match.params.pageId);
    this.setState({ isGiftEnabled: IsTrue(page.giftEnabled) });
  }

  render() {
    const notPageExist =  IsEmpty(this.props.match.params.pageId);

    return (
      <div
        className="d-flex"
        style={{
          ...this.props.style,
          width:           notPageExist ? '80px' : '300px',
          backgroundColor: Colours.White,
        }}
      >
        <div className="d-flex">
          <AppMenuSidebarMobile match={this.props.match} dispatchSideBarHide={this.props.dispatchSideBarHide} />
        </div>

        {
          !IsEmpty(this.props.match)
            && !IsEmpty(this.props.match.params.pageId)
            && (
              <>
                <div
                  className="d-flex flex-column"
                  style={{ width: '100%' }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center pl-1">
                    <Typography className="ml-3" variant="body1" color="inherit" style={{ color: Colours.Gray }}>Monstyr Dashboard</Typography>
                    <IconButton onClick={this.props.onLinkClicked}>
                      <Icons.Cross colour={Colours.Gray2} />
                    </IconButton>
                  </div>

                  <div
                    className="d-flex"
                    style={{
                      ...this.props.style,
                      width:           '100%',
                      flexDirection:   'column',
                      justifyContent:  'flex-start',
                      alignItems:      'flex-start',
                      backgroundColor: Colours.White,
                    }}
                  >
                    <div className="sub-menu-item">
                      <Typography variant="body1" color="inherit" style={{ color: Colours.Black, fontWeight: 'bold' }}>{this.props.pageSelected.name}</Typography>
                    </div>

                    {/* <MenuButton
                      text="Analytics"
                      to={Url.Analytics.replace(':pageId', this.props.match.params.pageId)}
                      onClick={this.props.onLinkClicked}
                    /> */}

                    <MenuButton
                      text="Posts"
                      to={Url.Posts.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                      onClick={this.props.onLinkClicked}
                    />
                    {(this.props.pageSelected.pageType === PageType.AreaManagement) && (
                      <>
                        <MenuButton
                          text="Generic Highlight"
                          to={Url.GenericHighlight.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />

                        <MenuButton
                          text="Food Review Highlight"
                          to={Url.FoodReviewHighlight.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />

                        <MenuButton
                          text="Special Highlights"
                          to={`${Url.PageHighlightListing.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}?isSpecial=true`}
                          onClick={this.props.onLinkClicked}
                        />

                        <MenuButton
                          text="Exclusive Highlights"
                          to={`${Url.PageHighlightListing.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}?isExclusive=true`}
                          onClick={this.props.onLinkClicked}
                        />

                        <MenuButton
                          text="Geographic Boundary"
                          to={Url.GeographicBoundary.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />
                      </>
                    )}

                    <MenuButton
                      text="Page"
                      to={Url.PageInfo.replace(':pageId', this.props.match.params.pageId)}
                      onClick={this.props.onLinkClicked}
                    />

                    {([PageType.FNB, PageType.Retailer].includes(this.props.pageSelected.pageType)) && (
                      <MenuButton
                        text="Outlets"
                        to={Url.Outlets.replace(':pageId', this.props.match.params.pageId)}
                        onClick={this.props.onLinkClicked}
                      />
                    )}

                    { this.state.isGiftEnabled && (
                      <>
                        <MenuButton
                          text="Coupons & Vouchers"
                          to={Url.CouponsAndVouchers.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />
                        <MenuButton
                          text="Loyalty Cards"
                          to={Url.LoyaltyCards.replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.onLoyaltyProgramLinkClickedHandler}
                        />
                      </>
                    ) }

                    {
                      IsAdminOnly(this.props.pageSelected.staffType) && (
                        <MenuButton
                          text="Access"
                          to={Url.Access.replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />
                      )
                    }

                    {
                      IsAdminOrContributor(this.props.pageSelected.staffType) && (
                        <MenuButton
                          text="Account"
                          to={Url.Account.replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />
                      )
                    }

                    {
                      IsAdminOrContributor(this.props.pageSelected.staffType) && (
                        <MenuButton
                          text="Business Profile"
                          to={Url.BusinessProfile.replace(':pageId', this.props.match.params.pageId)}
                          onClick={this.props.onLinkClicked}
                        />
                      )
                    }
                  </div>
                </div>
              </>
            )
        }
        <Backdrop open onClick={this.props.onLinkClicked} />
      </div>
    );
  }
}

/* fontWeight: [Url.FoodReviewHighlight].includes(this.props.match.path) ? 'bold' : 'normal' */
const MenuButton = ({ text, to, onClick }) => (
  <div className="position-relative w-100">
    <Button className="block p-0" style={{ borderRadius: 0 }} onClick={onClick}>
      <Link
        className="sub-menu-item"
        to={to}
      >
        <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
          {text}
        </Typography>
      </Link>
    </Button>
  </div>
);

MenuButton.propTypes = {
  text:    PropTypes.string.isRequired,
  to:      PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

PageSideBarMobile.propTypes = {
  dispatchSideBarHide: PropTypes.func.isRequired,
  onLinkClicked:       PropTypes.func.isRequired,
  pageSelected:        PropTypes.shape({
    name:      PropTypes.string,
    staffType: PropTypes.string,
    pageType:  PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId: PropTypes.string,
      pageId:     PropTypes.string,
    }),
  }).isRequired,
  dispatchLoyaltyProgramSideBarShow: PropTypes.func.isRequired,
};

PageSideBarMobile.defaultProps = {
  style: [],
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSideBarHide:               () => layoutAction.sideBarHide(dispatch),
  dispatchLoyaltyProgramSideBarShow: () => layoutAction.loyaltyProgramSideBarShow(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSideBarMobile);
