import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import layoutAction from '../../actions/layout';
import Colours from '../../styles/colours';
import { PageType, Url } from '../../common/constants';
import { IsAdminOnly, IsAdminOrContributor, IsTrue } from '../../common/checks';
import { PageMenuButton } from '../../styles/buttons';
import ApiService from '../../services/apiService';
import MText from '../Common/MText';
import { sideBarMenuItemFontSize } from '../../styles/common';

class PageSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGiftEnabled: false,
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  async fetchPage() {
    const page = await ApiService.getPageById(this.props.match.params.pageId);
    this.setState({ isGiftEnabled: IsTrue(page.giftEnabled) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageSelected && this.props.pageSelected.id !== prevProps.pageSelected.id) {
      this.fetchPage();
    }
  }

  onLinkClicked(type) {
    this.props.dispatchSideBarHide();
  }

  onLoyaltyProgramLinkClickedHandler = () => {
    this.props.dispatchLoyaltyProgramSideBarShow();
  }

  render() {
    return (
      <div
        className="d-flex"
        style={{
          ...this.props.style,
          flexDirection:           'column',
          justifyContent:          'flex-start',
          alignItems:              'flex-start',
          backgroundColor:         Colours.White,
          borderBottomRightRadius: '15px',
        }}
      >
        {/* <BackButton to={`/business/${this.props.match.params.businessId}/pages`} /> */}
        {/* <div
          className="d-flex flex-column justify-content-center"
          style={{
            color:                Colours.Black,
            backgroundColor:      Colours.White,
            width:                '100%',
            height:               '50px',
            borderTopRightRadius: '15px' }}
        >
          <Typography variant="caption" color="inherit" style={{ textAlign: 'center', padding: '10px'  }}>MONSTYR DASHBOARD</Typography>
        </div> */}

        <div className="sub-menu-item mt-1" style={{ height: '50px' }}>
          <MText text={this.props.pageSelected.name} size={18} />
        </div>

        {/** Jack: Temp hide Analytics until further notice. */}
        {/* <div style={{ position: 'relative', width: '100%' }}>
          <Button className="block p-0" style={{ borderRadius: 0 }}>
            <Link
              className="sub-menu-item"
              to={Url.Analytics.replace(':pageId', this.props.match.params.pageId)}
            >
              <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
                Analytics
              </Typography>
            </Link>
          </Button>
        </div> */}

        <div className="position-relative w-100">
          <PageMenuButton className="block p-0">
            <Link
              className="sub-menu-item"
              to={Url.Posts.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
            >
              <MText
                text="Posts"
                size={sideBarMenuItemFontSize}
                bold={[Url.Posts, Url.Post].includes(this.props.match.path)}
              />
            </Link>
          </PageMenuButton>
        </div>

        {(this.props.pageSelected.pageType === PageType.AreaManagement) && (
          <>
            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.GenericHighlight.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Generic Highlight"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.GenericHighlight].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>

            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.FoodReviewHighlight.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Food Review Highlight"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.FoodReviewHighlight].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>

            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={`${Url.PageHighlightListing.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}?isSpecial=true`}
                >
                  <MText
                    text="Special Highlights"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.PageHighlightListing].includes(this.props.match.path) && [window.location.search].includes('?isSpecial=true')}
                  />
                </Link>
              </PageMenuButton>
            </div>

            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={`${Url.PageHighlightListing.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}?isExclusive=true`}
                >
                  <MText
                    text="Exclusive Highlights"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.PageHighlightListing].includes(this.props.match.path) && [window.location.search].includes('?isExclusive=true')}
                  />
                </Link>
              </PageMenuButton>
            </div>

            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.GeographicBoundary.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Geographic Boundary"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.GeographicBoundary].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>
          </>
        )}

        {
          (this.props.pageSelected.pageType === PageType.FoodReview) && (
            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.Reviews.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Reviews"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.Reviews, Url.Review].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>
          )
        }

        <div className="position-relative w-100">
          <PageMenuButton className="block p-0">
            <Link
              className="sub-menu-item"
              to={Url.PageInfo.replace(':pageId', this.props.match.params.pageId)}
            >
              <MText
                text="Page"
                size={sideBarMenuItemFontSize}
                bold={[Url.PageInfo].includes(this.props.match.path)}
              />
            </Link>
          </PageMenuButton>
        </div>

        {
          [PageType.Retailer, PageType.FNB].includes(this.props.pageSelected.pageType) && (
            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.Outlets.replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Outlets"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.Outlets, Url.Outlet].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>
          )
        }

        {
          this.state.isGiftEnabled && (
            <>
              <div className="position-relative w-100">
                <PageMenuButton className="block p-0">
                  <Link
                    className="sub-menu-item"
                    to={Url.CouponsAndVouchers.replace(':businessId', this.props.match.params.businessId).replace(':pageId', this.props.match.params.pageId)}
                  >
                    <MText
                      text="Coupons & Vouchers"
                      size={sideBarMenuItemFontSize}
                      bold={[Url.CouponsAndVouchers, Url.Gift].includes(this.props.match.path)}
                    />
                  </Link>
                </PageMenuButton>
              </div>

              <div className="position-relative w-100">
                <PageMenuButton className="block p-0">
                  <Link
                    className="sub-menu-item"
                    to={Url.LoyaltyCards.replace(':pageId', this.props.match.params.pageId)}
                    onClick={this.onLoyaltyProgramLinkClickedHandler}
                  >
                    <MText
                      text="Loyalty Cards"
                      size={sideBarMenuItemFontSize}
                      bold={[Url.LoyaltyCards].includes(this.props.match.path)}
                    />
                  </Link>
                </PageMenuButton>
              </div>
            </>
          )
        }

        {
          IsAdminOnly(this.props.pageSelected.staffType) && (
            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.Access.replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Access"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.Access].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>
          )
        }
        {
          IsAdminOrContributor(this.props.pageSelected.staffType) && (
            <div className="position-relative w-100">
              <PageMenuButton className="block p-0">
                <Link
                  className="sub-menu-item"
                  to={Url.Account.replace(':pageId', this.props.match.params.pageId)}
                >
                  <MText
                    text="Account"
                    size={sideBarMenuItemFontSize}
                    bold={[Url.Account].includes(this.props.match.path)}
                  />
                </Link>
              </PageMenuButton>
            </div>
          )
        }
        <div className="position-relative w-100">
          <PageMenuButton className="block p-0">
            <Link
              className="sub-menu-item"
              to={Url.BusinessProfile.replace(':pageId', this.props.match.params.pageId)}
            >
              <MText
                text="Business Profile"
                size={sideBarMenuItemFontSize}
                bold={[Url.BusinessProfile].includes(this.props.match.path)}
              />
            </Link>
          </PageMenuButton>
        </div>
      </div>
    );
  }
}

PageSideBar.propTypes = {
  dispatchSideBarHide:               PropTypes.func.isRequired,
  dispatchLoyaltyProgramSideBarShow: PropTypes.func.isRequired,

  pageSelected: PropTypes.shape({
    id:        PropTypes.number,
    name:      PropTypes.string,
    staffType: PropTypes.string,
    pageType:  PropTypes.string,
  }).isRequired,

  style: PropTypes.shape({}),
  match: PropTypes.shape({
    path:   PropTypes.string.isRequired,
    params: PropTypes.shape({
      businessId: PropTypes.string,
      pageId:     PropTypes.string.isRequired,
    }),
  }).isRequired,
};

PageSideBar.defaultProps = {
  style: [],
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSideBarHide:               () => layoutAction.sideBarHide(dispatch),
  dispatchLoyaltyProgramSideBarShow: () => layoutAction.loyaltyProgramSideBarShow(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSideBar);
