import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import {  Error, StaffType } from '../../../common/constants';
import ApiService  from '../../../services/apiService';
import StyledRadio  from '../../../components/StyledRadio';
import Colours from '../../../styles/colours';

function AccessTable({ currentStaffList, pageSelected, setCurrentStaffList, isLoading, disabled }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [staffType, setStaffType] = useState(null);
  const [id, setId] = useState(null);
  const [email, setEmail] = useState('');

  return (
    <div className="p-2">
      <Dialog
        fullWidth
        size="sm"
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Staff</DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="inherit" style={{ color: Colours.Gray1 }}>
            {`Delete Staff with ID=${id} and email=`}
            <span style={{ color: Colours.Black, fontWeight: 'bolder' }}>{email}</span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions className="mb-2">
          <Button
            className="px-4"
            onClick={() => {
              ApiService.deleteBusinessStaff(id, pageSelected.id)
                .then(async () => {
                  const data = await ApiService.getBusinessStaff({ pageId: pageSelected.id });
                  setCurrentStaffList(data);
                  toast.success('Staff Deleted');
                  setOpenDelete(false);
                })
                .catch((error) => {
                  const errMessage = error.message ? error.message : Error.UnexpectedError;
                  toast.error(errMessage);
                });
            }}
            color="inherit"
            style={{ color: Colours.Gray1 }}
          >
            Confirm
          </Button>
          <Button className="px-4 mx-1" onClick={() => setOpenDelete(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        size="sm"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Staff</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="stafftype"
              row
              name="Staff Type"
              value={staffType}
              onChange={(e) => setStaffType(e.target.value)}
            >
              <FormControlLabel color="primary" value={StaffType.Admin} control={<StyledRadio size="small" />} label={StaffType.Admin} />
              <FormControlLabel value={StaffType.Contributor} control={<StyledRadio size="small" />} label={StaffType.Contributor} />
              <FormControlLabel value={StaffType.Observer} control={<StyledRadio size="small" />} label={StaffType.Observer} />
              <FormControlLabel value={StaffType.Finance} control={<StyledRadio size="small" />} label={StaffType.Finance} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions className="mb-2">
          <Button
            className="px-2"
            onClick={() => {
              ApiService.updateBusinessStaff(id, { type: staffType }, pageSelected.id)
                .then(async () => {
                  const data = await ApiService.getBusinessStaff({ pageId: pageSelected.id });
                  setCurrentStaffList(data);
                  toast.success('Staff Updated');
                  setOpenEdit(false);
                })
                .catch((error) => {
                  const errMessage = error.message ? error.message : Error.UnexpectedError;
                  toast.error(errMessage);
                });
            }}
            color="default"
          >
            Confirm
          </Button>
          <Button className="px-2 mx-3" onClick={() => setOpenEdit(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
      <MaterialTable
        title=""
        isLoading={isLoading}
        columns={[
          { title: 'Email', field: 'inviteEmail', editable: 'never' },
          {
            title:  'Staff Type',
            field:  'type',
            lookup: {
              Contributor: StaffType.Contributor,
              Observer:    StaffType.Observer,
              Admin:       StaffType.Admin,
              Finance:     StaffType.Finance,
            },
          },
          {
            title:    'Invite Status',
            field:    'inviteStatus',
            editable: 'never',
          },

        ]}
        data={currentStaffList}
        actions={[
          (rowData) => ({
            icon:    'delete',
            tooltip: 'Delete Staff',
            onClick: () => {
              setId(rowData.id);
              setEmail(rowData.inviteEmail);
              setOpenDelete(true);
            },
            disabled: rowData.isSelf || disabled,
          }),
          (rowData) => ({
            icon:    'edit',
            tooltip: 'Edit Staff',
            onClick: () => {
              setId(rowData.id);
              setEmail(rowData.inviteEmail);
              setStaffType(rowData.type);
              setOpenEdit(true);
            },
            disabled: rowData.isSelf || disabled,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          filtering:          true,
        }}
      />
    </div>
  );
}

AccessTable.defaultProps = {
  isLoading: false,
};

AccessTable.propTypes = {
  pageSelected: PropTypes.shape({
    id:        PropTypes.number.isRequired,
    staffType: PropTypes.string.isRequired,
  }).isRequired,
  setCurrentStaffList: PropTypes.func.isRequired,
  currentStaffList:    PropTypes.instanceOf(Array).isRequired,
  isLoading:           PropTypes.bool,
  disabled:            PropTypes.bool.isRequired,
};

export default AccessTable;
