const moment = require('moment');
const queryString = require('query-string');

// If length=0, means no limit to length of acronym
// E.g. { word="Marina Square", length=2} => "MS"
// E.g. { word="Marina Square", length=1} => "M"
// However, if word consists of only 1 token,
// then we return the first two chars of the token
// E.g. { word="Starbucks" } => "ST"
const AcronymWord = (phrase, length = 0) => {
  const words = phrase.split(' ');
  let acronym = '';

  if (length === 0) {
    return phrase;
  }
  if (words.length === 1) {
    return length < phrase.length ? phrase.substring(0, length).toUpperCase() : phrase;
  }

  for (let i = 0; i < (words.length >= length ? length : words.length); i += 1) {
    acronym += words[i].charAt(0).toUpperCase();
  }

  let wordIndex = 0;
  let letterIndex = 1;
  while (acronym.length < length) {
    if (!words[wordIndex].charAt(letterIndex)) {
      if ((wordIndex + 1) >= words.length && (letterIndex + 1) >= words[wordIndex].length) {
        return acronym;
      }
      wordIndex += 1;
      if (wordIndex === words.length) {
        letterIndex += 1;
        wordIndex = 0;
      }
      continue;
    }
    const letter = words[wordIndex].charAt(letterIndex).toUpperCase();
    const letterPos = (wordIndex + 1) * (letterIndex + 1) - 1;
    const acronymArr = acronym.split('');
    acronymArr.splice(letterPos, 0, letter);
    acronym = acronymArr.join('');

    if ((wordIndex + 1) >= words.length && (letterIndex + 1) >= words[wordIndex].length) {
      return acronym;
    }
    wordIndex += 1;
    if (wordIndex === words.length) {
      letterIndex += 1;
      wordIndex = 0;
    }
  }
  return acronym;
};

const IsEmpty = (obj) => {
  if (obj === null || obj === undefined || typeof obj === 'undefined' || obj === '' || obj === 'undefined' || obj === 'null') {
    return true;
  } else if (Array.isArray(obj) && obj.length === 0) {
    return true;
  } else {
    return false;
  }
};

const AcraFormatCheck = (num) => {
  const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!IsEmpty(num)) {
    const lastChar = num.charAt(num.length - 1);
    if (num.length >= 9 && num.length <= 10 && isNaN(lastChar.toString()) && !pattern.test(num)) {
      return true;
    }
  }

  return false;
};

const convertDataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const getFileDataUrl = async (file, onError = () => {}) => {
  try {
    const reader = new FileReader();
    return await new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = () => {
        const error = new Error('Error reading file');
        onError(error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  } catch (err) {
    onError(err);
  }
};

const formatDatetoString = (date) => {
  if (!IsEmpty(date)) {
    return moment(date).format('DD MMMM YYYY');
  }
};

const showDateInSGTime = (date) => (date ? moment(date).utcOffset(+8, false).format('YYYY-MM-DD') : null);

const formatDateWithTimetoString = (date, noTime) => {
  if (!IsEmpty(date)) {
    if (noTime) return moment(date).format('DD/MM/YYYY');
    return moment(date).format('DD/MM/YYYY, h:mm:ss a');
  }
};

const formatCardDateToString = (date) => {
  if (!IsEmpty(date)) {
    return moment(date).format('MM/YY');
  }
};

const getThumbnailFromVideoURL = (videoUrl) => videoUrl.replace(`${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.mp4`, `thumbnails/${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`);

const reduceFuncForTotal = ({ arr, item, initial = 0 }) => {
  if (item && arr) {
    return arr.reduce((total, next) => total + next.count, initial);
  } else {
    return 0;
  }
};

const datePeriodToString = (strDateStart, strDateEnd) => {
  let periodStr = '';
  if (!IsEmpty(strDateStart) && !IsEmpty(strDateEnd)) {
    const momentStart = moment(new Date(strDateStart));
    const momentEnd = moment(new Date(strDateEnd));
    if (momentStart.format('MMM') !== momentEnd.format('MMM')) {
      periodStr = `${momentStart.format('D')} ${momentStart.format('MMM')} - ${momentEnd.format('D')} ${momentEnd.format('MMM')} ${momentEnd.format('YYYY')}`;
    } else {
      periodStr = `${momentStart.format('D')} - ${momentEnd.format('D')} ${momentEnd.format('MMM')} ${momentEnd.format('YYYY')}`;
    }
  }
  return periodStr;
};

const EscapeSingleQuote = (str = '') => {
  if (!IsEmpty(str)) {
    return str.replace(/\'/g, "''");
  } else { return str; }
};

const emailValidation = (email) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  if (IsEmpty(email)) return 'Email is required';
  if (!email.match(regex)) return 'Email is invalid';

  return '';
};

// E.g. urlQueryStr = '?upgradePlan=true'
const ExtractParamsFromUrlQueryStr = (urlQueryStr = '') => {
  if (IsEmpty(urlQueryStr)) {
    return null;
  }
  return queryString.parse(urlQueryStr);
};

const passwordValidation = (password) => {
  if (IsEmpty(password)) return 'Password is required';
  if (password.length < 8) return 'Password must be at least 8 characters';

  return '';
};

const passwordMatchValidation = (password, matchPassword) => {
  if (IsEmpty(password) || IsEmpty(matchPassword)) return 'Password is required';
  if (password !== matchPassword) return 'Password does not match';

  return '';
};

const Sleep = (ms = 100) => new Promise((resolve) => setTimeout(resolve, ms));

const StringToLowercase = (string) => string.toLowerCase();

const Trim = (str = '', trimMiddle = false, trimMiddleToSingleSpace = false) => {
  if (!IsEmpty(str)) {
    str = str.trim();
    if (trimMiddleToSingleSpace) {
      str = str.replace(/\s\s+/g, ' ');
    }
    if (trimMiddle) {
      str = str.replace(/\s+/g, '');
    }
    return str;
  } else { return str; }
};

const ConvertToPascalCase = (string) => {
  string = Trim(string, false, true);
  if (!IsEmpty(string)) {
    return string
      .split(' ')
      .map((word) => word[0]
        .toUpperCase()
        .concat(word.slice(1).toLowerCase()))
      .join(' ');
  } else {
    return string;
  }
};

const formatDateToDayMonthNameYear = (date, hasTime = false) => {
  let dateFormat = 'D MMM YYYY';
  const timeFormat = 'HH:mm';

  if (hasTime) {
    dateFormat = `${dateFormat} ${timeFormat}`;
  }

  return (date ? moment(date).format(dateFormat) : null);
};

const convertTime12to24 = (time, isFrom2DigitHoursAndMins = false) => {
  if (isFrom2DigitHoursAndMins) {
    return moment(time, 'hh:mm A').format('HH:mm');
  } else {
    return moment(time, 'h:m A').format('HH:mm');
  }
};

const convertTime24to12 = (time, isReturn2DigitHoursAndMins = false) => {
  if (isReturn2DigitHoursAndMins) {
    return moment(time, 'HH:mm').format('hh:mm A');
  } else {
    return moment(time, 'HH:mm').format('h:m A');
  }
};

function convertDegreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function convertRadiansToDegrees(radians) {
  return radians * (180 / Math.PI);
}

function computeOffset(location, meter, degree = 0) {
  const EARTH_RADIUS = 6371009;

  const distance = meter / EARTH_RADIUS;
  const heading = convertDegreesToRadians(90 - degree);
  const fromLat = convertDegreesToRadians(location.lat);
  const fromLng = convertDegreesToRadians(location.lng);

  const cosDistance = Math.cos(distance);
  const sinDistance = Math.sin(distance);
  const sinFromLat = Math.sin(fromLat);
  const cosFromLat = Math.cos(fromLat);
  const sinLat = cosDistance * sinFromLat + sinDistance * cosFromLat * Math.cos(heading);

  const dLng = Math.atan2(sinDistance * cosFromLat * Math.sin(heading), cosDistance - sinFromLat * sinLat);
  return { lat: convertRadiansToDegrees(Math.asin(sinLat)), lng: convertRadiansToDegrees(fromLng + dLng) };
}

async function getImageSize(src) {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = () => {
      reject(new Error(`Failed to load image from ${src}`));
    };
    img.src = src;
  });
}

function isHexColour(str) {
  if (IsEmpty(str)) {
    return false;
  }
  return /^#(([0-9A-Fa-f]{2}){3,4}|[0-9A-Fa-f]{3})$/.test(str);
}

module.exports = {
  AcraFormatCheck,
  AcronymWord,
  IsEmpty,
  convertDataURLtoBlob,
  ConvertToPascalCase,
  formatDatetoString,
  formatDateWithTimetoString,
  formatCardDateToString,
  getImageSize,
  getFileDataUrl,
  datePeriodToString,
  EscapeSingleQuote,
  emailValidation,
  getThumbnailFromVideoURL,
  ExtractParamsFromUrlQueryStr,
  passwordValidation,
  passwordMatchValidation,
  reduceFuncForTotal,
  Sleep,
  showDateInSGTime,
  StringToLowercase,
  Trim,
  formatDateToDayMonthNameYear,
  convertTime12to24,
  convertTime24to12,
  computeOffset,
  isHexColour,
};
