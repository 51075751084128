/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { makeStyles, IconButton, Paper, Typography, TextField, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog }
  from '@material-ui/core';
import { IsEmpty, IfEmptyThrowError, IfFalseThrowError, IfTrueThrowError, IsAdminOrContributor } from '../../../common/checks';
import { Trim } from '../../../common/util';
import Constants, { Status, Error, AccountMaxImageUpload } from '../../../common/constants';

import MobileReviewPhotos from './ReviewMedia/photo/PhotoMobile';
import MobileReviewVideos from './ReviewMedia/video/VideoMobile';
import Tooltip from '../../../components/Common/ToolTip';
import MaterialDatePicker from '../../../components/Common/form/MaterialDatePicker';
import layoutAction from '../../../actions/layout';
import MButton from '../../../components/MButton';
import Colours from '../../../styles/colours';
import Loading from '../../../components/Loading';
import PreviewMobile from './PreviewMobile';
import ApiService from '../../../services/apiService';
import Icons from '../../../components/Common/Icons';
import ReviewAction from '../../../actions/review';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding:  '20px 0px',
    margin:   'auto',
    width:    '100%',
    maxWidth: '100%',
    flexGrow: 1,
  },
}));

const MobileUpdate = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [viewMediaVideo, setViewMediaVideo] = useState(true);
  const [viewMediaPhoto, setViewMediaPhoto] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState();
  const [status, setStatus] = useState('');
  const [title, setTitle] = useState('');
  const [reviewDate, setReviewDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [planModal, setPlanModal] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [maxUpload, setMaxUpload] = useState(0);
  const [disableInputFields, setDisableInputFields] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const [disableMedia, setDisableMedia] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title:   'Save to Live',
    message: 'Review cannot be updated back to Draft once it is Live, proceed?',
  });

  useEffect(() => {
    if (props && props.review) {
      const r = props.review;
      setStatus(r.status);
      setTitle(r.title || '');
      setReviewDate(r.reviewDate || '');
      setExpireDate(r.expireDate || '');

      // set the media view.
      if ((!IsEmpty(r.videos) && !IsEmpty(r.images)) || (!IsEmpty(r.videos) && IsEmpty(r.images))) {
        setViewMediaVideo(true);
        setViewMediaPhoto(false);
      } else {
        setViewMediaPhoto(true);
        setViewMediaVideo(false);
      }
      setDisableInputFields([Status.Live].includes(props.review.currentReviewStatus));
      setDisableMedia([Status.Live].includes(props.review.currentReviewStatus));
    }

    const maxNoUpload = AccountMaxImageUpload[props.page.plan];
    setAccountType(props.page.plan);
    setMaxUpload(maxNoUpload);
  }, [props.review]);

  const deleteReview = async () => {
    try {
      if (!IsEmpty(reviewToDelete)) {
        const softDeleteStatus = [Status.Live, Status.Paused];
        if (softDeleteStatus.includes(reviewToDelete.status)) {
          await ApiService.updateReview(reviewToDelete.id, { status: Status.Deleted });
        } else {
          await ApiService.deleteReview(reviewToDelete.id);
        }
        props.dispatchReviewUpdate({ status: Status.Deleted });
        toast.success('Review deleted');
        props.getReview();
        props.history.push(Constants.Url.Reviews.replace(':pageId', props.match.params.pageId));
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const validate = (r) => {
    r.title = Trim(r.title);
    r.reviewDate = Trim(r.reviewDate);
    r.expireDate = Trim(r.expireDate);

    IfEmptyThrowError(r.title, 'Title is required');
    IfTrueThrowError((IsEmpty(r.images) && IsEmpty(r.videos)), 'Upload a photo/video');

    if (!IsEmpty(r.reviewDate) && !IsEmpty(r.expireDate)) {
      const dateReview = moment(r.expireDate).isAfter(r.reviewDate, 'day');
      if (!dateReview) {
        IfFalseThrowError(dateReview, 'Review expire date cannot be earlier than review date');
      }
    }

    props.dispatchReviewUpdate({ ...r });
  };

  const handleUpdate = async (updatedStatus) => {
    try {
      setConfirmPopup(false);
      const r = props.review;
      validate(r);

      setLoading(true);

      // Standardize to pass Singapore timezone to the server
      const startDate = r.reviewDate ? new Date(r.reviewDate) : null;
      const reviewStartDate = startDate
        ? new Date(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()} 00:00:00 +8:00`) : null;
      const endDate = r.expireDate ? new Date(r.expireDate) : null;
      const reviewExpireDate = endDate ? new Date(`${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} 23:59:59 +8:00`) : null;

      let reviewStatus = updatedStatus;
      if (props.page.status === Status.Pending && updatedStatus === Status.Pending) {
        reviewStatus = Status.Pending;
      }

      const payload = {
        title:       r.title,
        content:     r.content,
        status:      reviewStatus,
        images:      r.images,
        videos:      r.videos,
        reviewDate:  reviewStartDate,
        expireDate:  reviewExpireDate,
        url:         r.url ? String(r.url).trim() : '',
        tags:        r.tags,
        cuisineTags: r.cuisineTags,
      };

      // eslint-disable-next-line react/prop-types
      await ApiService.updateReview(props.match.params.reviewId, payload)
        .then((review) => {
          props.dispatchReviewUpdate({ status: review.status, currentReviewStatus: review.status });
          toast.success('Review updated');
          setLoading(false);
          props.getReview();
        })
        .catch((err) => {
          if (err.message === Error.MaxNumberPublishedReview) {
            setPlanModal(true);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (loading ? <Loading />
    : (
      <>
        <div className="container-fluid" style={{ background: Colours.LightGray }}>
          <div className="row pt-3" style={{ backgroundColor: Colours.White }}>
            <div className="col-12 d-flex flex-row justify-content-between align-items-center">
              <IconButton
                style={{ width: 50, height: 50 }}
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <Icons.ArrowBack colour={Colours.Black} />
              </IconButton>

              <div className="d-flex flex-column justify-content-center">
                <Typography variant="h6" align="center">Edit Review</Typography>
              </div>

              { props.review.status !== Status.Deleted ? (
                <Tooltip title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}>
                  <MButton
                    text="Delete"
                    textSize="subtitle1"
                    textColour={Colours.Red}
                    disabled={!IsAdminOrContributor(props.page.staffType)}
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setReviewToDelete(props.review);
                    }}
                  />
                </Tooltip>
              ) : <div style={{ width: 35 }} />}
            </div>

            <div className="col-12 pb-2">
              <div className="row">
                <div className="col-3">
                  <Typography className="ml-2" variant="subtitle1">
                    ID:
                    {' '}
                    {props.review.id}
                  </Typography>
                </div>

                <div className="col-6">
                  <div className="d-flex flex-row justify-content-center flex-nowrap">
                    <Typography className="mr-2" variant="subtitle1">Status</Typography>
                    <Typography variant="subtitle1" style={{ color: (status !== Status.Draft) ? Colours.Primary : Colours.Orange }}>
                      {status || ''}
                    </Typography>
                    <Tooltip title={`Review's status`}>
                      <Typography className="mr-2" variant="subtitle1">
                        <Icons.InfoOutlined colour={Colours.LightGray} fontSize="1.3rem" style={{ marginLeft: '5px' }} />
                      </Typography>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-12 mb-2 d-flex flex-column">
              <Paper className={`${classes.paper}`}>
                <div className="px-3">

                  <div className="mb-2">
                    {/* <Typography
                      variant="body1"
                      color="inherit"
                      className="d-inline-block mr-2"
                      style={{
                        fontWeight:   'bold',
                        padding:      '5px 10px',
                        border:       `1px solid ${(viewMediaVideo) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.Gray}`,
                        borderRadius: 100,
                        background:   (viewMediaVideo) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.White,
                        color:        (viewMediaVideo) ? Colours.White : Colours.Gray,
                      }}
                      onClick={() => {
                        setViewMediaVideo(true);
                        setViewMediaPhoto(false);
                      }}
                    >
                      Videos
                    </Typography> */}

                    <Typography
                      variant="body1"
                      color="inherit"
                      className="d-inline-block"
                      style={{
                        fontWeight:   'bold',
                        padding:      '5px 10px',
                        border:       `1px solid ${(viewMediaPhoto) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.Gray}`,
                        borderRadius: 100,
                        background:   (viewMediaPhoto) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.White,
                        color:        (viewMediaPhoto) ? Colours.White : Colours.Gray,
                      }}
                      onClick={() => {
                        setViewMediaPhoto(true);
                        setViewMediaVideo(false);
                      }}
                    >
                      Photos
                    </Typography>
                  </div>

                  <div className="container-fluid p-0 m-0">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          ((!IsEmpty(props.review.videos) && viewMediaVideo) || (IsEmpty(props.review.videos) && viewMediaVideo))
                            ? (
                              <MobileReviewVideos
                                {...props}
                                postId={props.review.id}
                                videos={props.review.videos || []}
                                onVideoSort={(vid) => props.dispatchReviewUpdate({ vid })}
                                onVideoChanged={(vid) => props.dispatchReviewUpdate({ vid })}
                                disabled={!IsAdminOrContributor(props.page.staffType) || (disableMedia)}
                              />
                            )
                            : (
                              <MobileReviewPhotos
                                {...props}
                                match={props.match}
                                images={props.review.images || []}
                                onSetPostImages={(imgs) => props.dispatchReviewUpdate({ imgs })}
                                onSort={(imgs) => props.dispatchReviewUpdate({ imgs })}
                                showSaveImgPosBtn={false}
                                disabled={!IsAdminOrContributor(props.page.staffType) || (disableMedia)}
                              />
                            )
                        }
                      </div>
                    </div>
                  </div>

                </div>
              </Paper>
            </div>

            <div className="col-12 d-flex flex-column">
              <Paper className={`${classes.paper}`}>
                <div className="px-3">
                  <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                    Title
                    <sup style={{ color: Colours.Red }}>*</sup>
                  </Typography>

                  <TextField
                    id="standard-multiline-flexible"
                    value={title}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => props.dispatchReviewUpdate({ title: e.currentTarget.value })}
                  />
                </div>

                <hr />

                <div className="px-3">
                  <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                    Review Date
                  </Typography>

                  <div className="container d-block px-0 mx-0">

                    <div className="pr-0">
                      <MaterialDatePicker
                        minDate={false}
                        onChange={(date = new Date()) => {
                          if (!disableInputFields) {
                            const rDate = date.toString('yyyy-MM-dd');
                                props.dispatchReviewUpdate({ reviewDate: rDate });
                          }
                        }}
                        value={!reviewDate ? null : new Date(reviewDate)}
                        label="From"
                        size="small"
                        variant="outlined"
                      />
                    </div>

                  </div>

                  <Typography variant="body1" color="inherit" className="mt-2 mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                    Expire Date
                  </Typography>

                  <div className="container d-block px-0 mx-0">

                    <div className="pr-0">
                      <MaterialDatePicker
                        minDate={false}
                        onChange={(date = new Date()) => {
                          if (!disableInputFields) {
                            const eDate = date.toString('yyyy-MM-dd');
                                props.dispatchReviewUpdate({ expireDate: eDate });
                          }
                        }}
                        value={!expireDate ? null : new Date(expireDate)}
                        label="From"
                        size="small"
                        variant="outlined"
                      />
                    </div>

                  </div>
                </div>

                <hr />

              </Paper>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12 px-3">
              <Button
                variant="outlined"
                size="large"
                className="btn-block"
                onClick={() => {
                  props.dispatchSlidingPanelMobile({
                    open:    true,
                    content: <PreviewMobile {...props} slidePreview />,
                    title:   'App Preview',
                  });
                }}
              >
                <Typography>Preview</Typography>
              </Button>
            </div>

            {
              // *Note: If the status array is modified, pls rmb to modify the dialog msg below accordingly
              [Status.Draft, Status.Live].includes(props.review.currentReviewStatus) && (
                <>
                  <div className="col-12 px-3 my-3">
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      className="btn-block"
                      disabled={!IsAdminOrContributor(props.page.staffType)}
                      onClick={() => {
                        if (props.review.currentReviewStatus === Status.Live) {
                          handleUpdate(Status.Live);
                        } else {
                          setConfirmationStatus(Status.Live);
                          setDialogContent({
                            title:   'Save to Live',
                            message: props.review.currentReviewStatus === Status.Draft
                              ? `Review cannot be updated back to Draft once it is Live, proceed?`
                              : `Update review's status from ${props.review.currentReviewStatus} to Live, proceed?`,
                          });
                          setConfirmPopup(true);
                        }
                      }}
                    >
                      <Typography>Save</Typography>
                    </Button>
                  </div>
                </>
              )
            }

            { [Status.Pending].includes(props.review.currentReviewStatus) && (
              <>
                <div className="col-12 px-3 my-3">
                  <Tooltip
                    title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}
                    style={{
                      width: '100vw',
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      className="btn-block"
                      disabled={!IsAdminOrContributor(props.page.staffType)}
                      onClick={() => handleUpdate(Status.Draft)}
                    >
                      <Typography>Draft</Typography>
                    </Button>
                  </Tooltip>
                </div>

                <div className="col-12 px-3 mb-3">
                  <Tooltip
                    title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}
                    style={{
                      width: '100vw',
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      className="btn-block"
                      disabled={!IsAdminOrContributor(props.page.staffType)}
                      onClick={() => handleUpdate(Status.Pending)}
                    >
                      <Typography>Save</Typography>
                    </Button>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </div>

        <Dialog
          fullWidth
          size="sm"
          open={confirmPopup}
          onClose={() => setConfirmPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{ dialogContent.title }</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" color="inherit">{ dialogContent.message }</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpdate(confirmationStatus)} color="default" className="mr-4">Proceed</Button>
            <Button onClick={() => setConfirmPopup(false)} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          size="sm"
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Review</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this review?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mb-2">
            <Button className="px-2" onClick={() => deleteReview()} color="default">Confirm</Button>
            <Button className="px-2 mx-3" onClick={() => setOpenDeleteDialog(false)} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={planModal}
          fullWidth
          keepMounted
          onClose={() => planModal(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{ Error.MaxNumberPublishedReview }</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Your current account plan is :
              {' '}
              <span style={{ fontWeight: 'bold', color: Colours.Black }}>{accountType}</span>
              <br />
              The limit of
              {' '}
              {accountType}
              {' '}
              plan is
              {' '}
              <span style={{ fontWeight: 'bold', color: Colours.Black }}>{maxUpload}</span>
              {' '}
              media.
              Upgrade account plan to increase limit.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPlanModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    ));
};

MobileUpdate.defaultProps = {
  match:  {},
  videos: [],
  images: [],
};

MobileUpdate.propTypes = {
  match:   PropTypes.shape(),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push:   PropTypes.func.isRequired,
  }).isRequired,
  getReview:                  PropTypes.func.isRequired,
  dispatchReviewUpdate:       PropTypes.func.isRequired,
  dispatchSlidingPanelMobile: PropTypes.func.isRequired,
  review:                     PropTypes.shape({
    id:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title:               PropTypes.string,
    content:             PropTypes.string,
    reviewDate:          PropTypes.string,
    expireDate:          PropTypes.string,
    status:              PropTypes.string,
    images:              PropTypes.arrayOf(PropTypes.shape({})),
    videos:              PropTypes.arrayOf(PropTypes.string),
    url:                 PropTypes.string,
    tags:                PropTypes.arrayOf(PropTypes.string),
    cuisineTags:         PropTypes.arrayOf(PropTypes.string),
    reviewPlaces:        PropTypes.arrayOf(PropTypes.shape({})),
    reviewLocations:     PropTypes.arrayOf(PropTypes.shape({})),
    currentReviewStatus: PropTypes.string,
  }).isRequired,
  page: PropTypes.shape({
    staffType: PropTypes.string,
    type:      PropTypes.string,
    status:    PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      display:  PropTypes.string,
      original: PropTypes.string,
    }),
  ),
  videos: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  business: state.business.selected,
  page:     state.page.selected,
  review:   state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSlidingPanelMobile: (data) => layoutAction.slidingPanelMobile(dispatch, data),
  dispatchReviewUpdate:       (review) => ReviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileUpdate);
