import React, { useEffect, useState } from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText }
  from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Colours from '../styles/colours';
import { AccountMaxPostPublished, AccountMaxReviewPublished, Error, WindowSize } from '../common/constants';
import { IsEmpty } from '../common/checks';

const SubscriptionLimitModal = (props) => {
  const [maxPublishedError, setMaxPublishedError] = useState('');
  const [accountMaxPublished, setAccountMaxPublished] = useState('');
  const [postOrReview, setPostOrReview] = useState('');

  useEffect(() => {
    if (!IsEmpty(props.post.id)) {
      //Set Dialog to Max Published Post(s)
      setMaxPublishedError(Error.MaxNumberPublishedPost);
      setAccountMaxPublished(AccountMaxPostPublished[props.accountType]);
      setPostOrReview('post');
    } else {
      //Set Dialog to Max Published Review(s)
      setMaxPublishedError(Error.MaxNumberPublishedReview);
      setAccountMaxPublished(AccountMaxReviewPublished[props.accountType]);
      setPostOrReview('review');
    }
  });

  const SubscriptionLimitDialog = () => (
    <>
      <DialogTitle id="alert-dialog-slide-title">{maxPublishedError}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your current subscription plan :
          {' '}

          { props.windowSize === WindowSize.Mobile && <br /> }

          <span style={{ fontWeight: 'bold', color: Colours.Black }}>
            {props.accountType}
            {' '}
            Tier
          </span>

          <br />
          {props.windowSize === WindowSize.Mobile && <br />}

          Monthly live
          {' '}
          {postOrReview}
          (s) quota:
          {' '}
          <span style={{ fontWeight: 'bold', color: Colours.Black }}>{accountMaxPublished}</span>

          <br />
          <br />
          To find out more or upgrade your subscription plan, click
          {' '}
          <a href={`/support?upgradePlan=true&pageName=${props.page.name}&pageId=${props.page.id}`}>here</a>
          .
        </DialogContentText>
      </DialogContent>
    </>
  );

  return (
    <Dialog
      open={props.open}
      fullWidth
      keepMounted
      onClose={() => props.close()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <SubscriptionLimitDialog
        accountType={props.accountType}
        maxPublishedError={Error.MaxNumberPublishedPost}
        accountMaxPublished={AccountMaxPostPublished[props.accountType]}
        page={props.page}
        windowSize={props.windowSize}
      />
      <DialogActions>
        <Button onClick={() => props.close()} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SubscriptionLimitModal.propTypes = {
  accountType: PropTypes.string.isRequired,
  close:       PropTypes.func.isRequired,
  open:        PropTypes.bool,
  page:        PropTypes.shape({
    id:   PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
  post:       PropTypes.shape({
    id: PropTypes.string,
  }),
};

SubscriptionLimitModal.defaultProps = {
  open: false,
  post: {
    id: null,
  },
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(SubscriptionLimitModal);
